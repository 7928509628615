/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

require('@fontsource/barlow/latin-400.css');
require('@fontsource/barlow/latin-ext-400.css');
require('@fontsource/barlow/latin-500.css');
require('@fontsource/barlow/latin-ext-500.css');
require('@fontsource/barlow/latin-600.css');
require('@fontsource/barlow/latin-ext-600.css');

require('normalize.css');
require('./src/styles/variables.css');
require('./src/styles/print.css');
require('./src/styles/screen.css');

const smoothscroll = require('smoothscroll-polyfill');

exports.onClientEntry = () => {
  smoothscroll.polyfill();
};

exports.onRouteUpdate = ({ location }) => {
  if (window.dataLayer) {
    window.dataLayer.push({ event: 'pageview', location: location.pathname });
  }
};
