// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-akarugiveaway-index-jsx": () => import("./../../../src/pages/akarugiveaway/index.jsx" /* webpackChunkName: "component---src-pages-akarugiveaway-index-jsx" */),
  "component---src-pages-faq-index-jsx": () => import("./../../../src/pages/faq/index.jsx" /* webpackChunkName: "component---src-pages-faq-index-jsx" */),
  "component---src-pages-flavorgiveaway-index-jsx": () => import("./../../../src/pages/flavorgiveaway/index.jsx" /* webpackChunkName: "component---src-pages-flavorgiveaway-index-jsx" */),
  "component---src-pages-giveaways-index-jsx": () => import("./../../../src/pages/giveaways/index.jsx" /* webpackChunkName: "component---src-pages-giveaways-index-jsx" */),
  "component---src-pages-hub-index-jsx": () => import("./../../../src/pages/hub/index.jsx" /* webpackChunkName: "component---src-pages-hub-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-maskedgiveaway-index-jsx": () => import("./../../../src/pages/maskedgiveaway/index.jsx" /* webpackChunkName: "component---src-pages-maskedgiveaway-index-jsx" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-pages-sajugiveaway-index-jsx": () => import("./../../../src/pages/sajugiveaway/index.jsx" /* webpackChunkName: "component---src-pages-sajugiveaway-index-jsx" */),
  "component---src-pages-sell-dota-2-items-index-jsx": () => import("./../../../src/pages/sell-dota-2-items/index.jsx" /* webpackChunkName: "component---src-pages-sell-dota-2-items-index-jsx" */),
  "component---src-pages-sell-tf-2-team-fortress-2-items-index-jsx": () => import("./../../../src/pages/sell-tf2-team-fortress-2-items/index.jsx" /* webpackChunkName: "component---src-pages-sell-tf-2-team-fortress-2-items-index-jsx" */),
  "component---src-pages-sitemap-index-jsx": () => import("./../../../src/pages/sitemap/index.jsx" /* webpackChunkName: "component---src-pages-sitemap-index-jsx" */),
  "component---src-pages-stompgiveaway-index-jsx": () => import("./../../../src/pages/stompgiveaway/index.jsx" /* webpackChunkName: "component---src-pages-stompgiveaway-index-jsx" */),
  "component---src-pages-terms-of-service-index-jsx": () => import("./../../../src/pages/terms-of-service/index.jsx" /* webpackChunkName: "component---src-pages-terms-of-service-index-jsx" */),
  "component---src-pages-tudsongiveaway-index-jsx": () => import("./../../../src/pages/tudsongiveaway/index.jsx" /* webpackChunkName: "component---src-pages-tudsongiveaway-index-jsx" */)
}

