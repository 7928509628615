module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W8SQLMZ","enableWebVitalsTracking":true,"includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#00b22d"},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":100,"maintainCase":true,"removeAccents":true,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sell CS:GO Skins for Real Money & Withdraw to the e-wallet of your choice | Skinwallet","short_name":"Skinwallet","description":"Sell your CSGO skins fast and cash out instantly! Log in with your Steam, evaluate your inventory, sell skins and pay out money in 5 minutes.","start_url":"/start","background_color":"#222221","theme_color":"#222221","display":"standalone","icons":[{"src":"/android-chrome-192x192.png?v3=8c5bc7bf7","sizes":"192x192","type":"image/png"},{"src":"/android-chrome-512x512.png?v3=8c5bc7bf7","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/dawidchomicz/SourceCode/skinwallet/homepage-2.0/src/intl","languages":["pl","ru","tr","de","fr","pt","br","se"],"defaultLanguage":"en","redirectDefaultLanguageToRoot":true,"redirect":false,"ignoredPaths":["/maskedgiveaway/","/sajugiveaway/","/tudsongiveaway/","/stompgiveaway/","/akarugiveaway/","/flavorgiveaway/"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
